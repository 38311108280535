














import { Component, Vue } from 'vue-property-decorator';
import { THEME_DATA } from '@/mock/ThemeMock';
import LoginApi from '@/api/system/LoginApi';
@Component({
  name: 'App',
  components: {}
})
export default class Login extends Vue {
  platIconTitleData: any = {};
  isLoginLoading: boolean = false;
  openSmsLogin: boolean = false;
  sendSmsFlag: boolean = false;
  openRegister: boolean = false;
  retisterPath: boolean = false;

  get loginType() {
    return this.$store.getters.loginType;
  }

  created() {
    // 获取初始化数据
    this.getPlatIconTitle();
  }
  mounted() {
    let box = document.querySelector('.left-title') as any;
    box.innerText = '';
    box.innerText = '诚东资产管理有限公司';
    box.style.color = 'rgb(0,94,187)';
  }
  /**
   * 获取系统登录页面、首页页面配置信息
   * @param {number} param
   * @returns {number}
   */
  getPlatIconTitle() {
    this.platIconTitleData = THEME_DATA;
    this.platIconTitleData.icontitlePlatCnname = this.$t(
      this.platIconTitleData.icontitlePlatCnname
    );
    this.platIconTitleData.icontitleCompany = this.$t(
      this.platIconTitleData.icontitleCompany
    );
    this.platIconTitleData.icontitleDoorTitle = this.$t(
      this.platIconTitleData.icontitleDoorTitle
    );
  }
  /**
   * 密码登录
   * @param {number} param
   * @returns {number}
   */
  handlePwdLogin(formData) {
    this.isLoginLoading = true;
    let data = {
      account: formData.username,
      password: formData.password
    };
    let v = data.password;
    LoginApi.accessCode(data).then(async (res: any) => {
      if (res.code == '1') {
        let accessCode = res.data.accessCode;
        let clientId = process.env.VUE_APP_CLIENT_ID;
        let flag = await this.setPassword(data, v);
        if (flag) {
          let url = `${process.env.VUE_APP_SERVER_CODE}auth/login?accessCode=${accessCode}&clientId=${clientId}`;
          window.location.href = url;
          // LoginApi.login({ accessCode, clientId }).then((res: any) => {
          //   this.$message.error(res.message);
          // });
          this.isLoginLoading = false;
          // this.$router.push('/home');
        }
      } else {
        this.$message.error(res.message);
        this.isLoginLoading = false;
      }
    });
  }
  setPassword(data, v) {
    return new Promise((success, error) => {
      setTimeout(() => {
        if (v == 'Aa123456!' || v == 'abc#123' || v == 'ebYPeApx7%') {
          let obj = {
            defaultPassword: v,
            changeFlag: true,
            passwordSafe: 'default',
            tips: '系统监测到您的密码仍为初始密码，请尽快修改！'
          };

          localStorage.setItem('changeFlag', JSON.stringify(obj));
        } else {
           let passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*()_=])[\da-zA-Z~!@#$%^&*()_=]{8,16}$/;
          if (passwordReg.test(v) == false) {
            let obj = {
              defaultPassword: v,
              changeFlag: true,
              passwordSafe: 'weak',
              tips: '系统监测到您的密码为弱类型密码，请尽快修改！'
            };
            localStorage.setItem('changeFlag', JSON.stringify(obj));
          }
        }
        success(true);
      }, 1000);
    });
  }
  /**
   * 短信
   * @param {number} param
   * @returns {number}
   */
  handleSmsLogin() {}
  /**
   * 跳转到注册页面
   * @param {number} param
   * @returns {number}
   */
  handleRegister() {}
  beforeRouteLeave(to, from, next) {
    console.log(to);
    console.log(from);
    next();
  }
}
